import UIkit from 'uikit'

const wrapYoutubeVideos = () => {
  var youTubeVideos = Array.from(document.querySelectorAll('iframe[src*="youtube"]'))
  youTubeVideos.forEach(el => {
    let wrapper = document.createElement('div')
    wrapper.classList.add("video-container")
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el)
  })
}

export const onInitialClientRender = () => {
  UIkit.offcanvas("#search", {overlay: true, flip: true })
  UIkit.offcanvas("#offcanvasMenu", {overlay: true})
  UIkit.sticky("#stickyNav", {"sel-target": ".uk-navbar-container", "cls-active": "uk-navbar-sticky"})
  // UIkit.slider("#discoverMore", {"sets": true,  "autoplay-interval": 4000, "center": true})
  Array.from(document.querySelectorAll(".discover-more")).forEach((el) => {
    UIkit.slider(el, {"sets": true,  "autoplay-interval": 4000, "center": true})
  })
  wrapYoutubeVideos();
}

export const onRouteUpdate = ({location, prevLocation}) => {
  if (prevLocation) {
    if(window.__weatherwidget_init){
      window.__weatherwidget_init()
    } else {
      setTimeout(() => {
        window.__weatherwidget_init()
      }, 1)
    }
    UIkit.offcanvas("#search", {overlay: true, flip: true })
    UIkit.offcanvas("#offcanvasMenu", {overlay: true})
    UIkit.sticky("#stickyNav", {"sel-target": ".uk-navbar-container", "cls-active": "uk-navbar-sticky"})
    Array.from(document.querySelectorAll(".discover-more")).forEach((el) => {
      UIkit.slider(el, {"sets": true,  "autoplay-interval": 4000, "center": true})
    })

    wrapYoutubeVideos();
  }
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {

  if (prevLocation && (location.pathname.replace(/\//g, '') !== prevLocation.pathname.replace(/\//g, ''))) {
    UIkit.offcanvas("#search").$destroy(true)
    UIkit.offcanvas("#offcanvasMenu").$destroy(true)
    UIkit.sticky("#stickyNav").$destroy(true)
    Array.from(document.querySelectorAll(".discover-more")).forEach((el) => {
      UIkit.slider(el).$destroy(true)
      // UIkit.slider(el, {"sets": true,  "autoplay-interval": 4000, "center": true})
    })
    // UIkit.slider("#discoverMore") && UIkit.slider("#discoverMore").$destroy(true)
  }
}
